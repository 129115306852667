import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "./firebase";
import { Scanner } from "@yudiel/react-qr-scanner";
import { env } from "./env";

const getAdminDashboardLink = (orderID) => {
  if (env.production) {
    return `https://admin.wecarrybags.co.uk/orders/show/${orderID}`;
  } else {
    return `https://main--friendly-elf-134e1d.netlify.app/orders/show/${orderID}`;
  }
};

const AdminScanning = () => {
  const [bag, setBagInternal] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [scanError, setScanError] = useState(null);

  const setBag = (bagText) => {
    try {
      const bagJson = JSON.parse(bagText);
      if (bagJson.bagID) {
        setBagInternal(bagJson.bagID);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const loadOrderData = async () => {
      if (bag) {
        const q = query(collection(db, "bags"), where("bagID", "==", bag));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {
          const data = querySnapshot.docs[0].data();
          setOrderData(data);
        } else {
          setScanError("Bag not used by customer");
        }
      }
    };
    loadOrderData();
  }, [bag]);

  if (bag === null) {
    const videoConstraints = {
      facingMode: { ideal: "environment" },
    };

    return (
      <Scanner
        onResult={(text) => {
          setBag(text);
        }}
        style={{ width: "100%", height: "60%" }}
        constraints={videoConstraints}
      />
    );
  } else if (orderData === null) {
    if (scanError !== null) {
      return <>{scanError}</>;
    } else {
      return <>Loading...</>;
    }
  } else {
    const link = getAdminDashboardLink(orderData.orderID);
    return (
      <a href={link} className="text-blue-500 cursor-pointer hover:underline">
        Click to find order information
      </a>
    );
  }
};

export default AdminScanning;
